import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { theme } from "../theme";
import { useAddRoleMutation } from "../apis/rolesAPI";
import { getOrganisation } from "../slices/organisationSlice";
import { selector } from "../store";
import { useNavigate } from "react-router-dom";
import colors from "../colors";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { IUserStatusType } from "@backend/model/role";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import { useListSettingsQuery } from "../apis/settingsAPI";
import { isSettingEnabled } from "../utils/settingUtils";
import { useInviteToCourseMutation, useLazyGetCourseLinkQuery, useListCoursesQuery } from "../apis/coursesAPI";
import { InviteModal } from "./components/modals/inviteModal";

export const AddPeople = () => {
  const org: any = selector((state) => getOrganisation(state));

  const navigete = useNavigate();
  const { t } = useTranslation();

  const [userStatus, setUserStatus] = useState<IUserStatusType>("employee");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | undefined>();
  const [occupationalSafetyCard, setOccupationalSafetyCard] =
    useState<string>("");

  const [error, setError] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [addRole] = useAddRoleMutation();

  const { data: settings, isFetching: loadingSettings } = useListSettingsQuery({
    organisationId: org?.organisationId || "",
  });
  const { data: courses, isLoading: coursesLoading } = useListCoursesQuery(
    org?.organisationId,
    {
      skip: !org,
    }
  );
  
  const [invite] = useInviteToCourseMutation();
  const [getLink] = useLazyGetCourseLinkQuery();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserStatus(
      ((event.target as HTMLInputElement).value as IUserStatusType) ||
        "employee"
    );
  };

  const onAdd = useCallback(async () => {
    if (org) {
      try {
        await addRole({
          organisationId: org.organisationId,
          firstName,
          lastName,
          email,
          company,
          phoneNumber,
          taxNumber,
          dateOfBirth: dateOfBirth?.toDate(),
          occupationalSafetyCard,
          role: "user",
          userStatus: userStatus || "employee",
        }).unwrap();
        navigete(-1);
      } catch (err) {
        setError(true);
      }
    }
  }, [
    addRole,
    org,
    firstName,
    lastName,
    email,
    company,
    phoneNumber,
    taxNumber,
    dateOfBirth,
    navigete,
    userStatus,
    occupationalSafetyCard,
  ]);

  const onAddAndInvite = useCallback(async (courses: string[]) => {
    if (org) {
      try {
        // Add a role
        const result = await addRole({
          organisationId: org.organisationId,
          firstName,
          lastName,
          email,
          company,
          phoneNumber,
          taxNumber,
          dateOfBirth: dateOfBirth?.toDate(),
          occupationalSafetyCard,
          role: "user",
          userStatus: userStatus || "employee",
        }).unwrap();
        
        // If a person was successfully added, invite it to selected courses
        if(result){
          await Promise.all(
            courses.map(async (course) => {
              await invite({
                organisationId: org?.organisationId || "",
                courseId: course,
                emails: [email], // Only invite the current person.
              }).unwrap();
              const link = await getLink({
                organisationId: org?.organisationId || "",
                courseId: course,
                email: email,
              }).unwrap();
              console.log(link);
            })
          );
        }

      } catch (err) {
        setError(true);
      }
    }
  }, [
    addRole,
    org,
    firstName,
    lastName,
    email,
    company,
    phoneNumber,
    taxNumber,
    dateOfBirth,
    navigete,
    userStatus,
    occupationalSafetyCard,
  ]);


  return (
    <ViewContainer loading={loadingSettings}>
      <Box
        width={"100%"}
        height={"100%"}
        position={"relative"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
      >
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {t("errorOccured")}
          </Alert>
        </Snackbar>
        <Typography variant="h4">Add people</Typography>
        <Divider style={{ width: "100%", marginTop: theme.spacing(2) }} />

        <FormControl>
          <FormLabel id="user-status-label">Status</FormLabel>
          <RadioGroup
            aria-labelledby="user-status-label"
            defaultValue="employee"
            name="user-status"
            value={userStatus}
            onChange={handleChange}
          >
            <FormControlLabel
              value={"employee"}
              control={<Radio />}
              label="Employee"
            />
            <FormControlLabel
              value={"contractor"}
              control={<Radio />}
              label="Contractor"
            />
            <FormControlLabel
              value={"visitor"}
              control={<Radio />}
              label="Visitor"
            />
          </RadioGroup>
        </FormControl>

        <TextField
          label={t("firstName")}
          margin="normal"
          style={{ minWidth: 400 }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          label={t("lastName")}
          margin="normal"
          style={{ minWidth: 400 }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <TextField
          label={t("email")}
          margin="normal"
          style={{ minWidth: 400 }}
          value={email}
          error={!!email && !/.+@.+\..+/.test(email)}
          onChange={(e) => setEmail(e.target.value)}
        />
        {settings && isSettingEnabled(settings, "phoneVisible") && (
          <TextField
            label={t("phoneNumber")}
            margin="normal"
            style={{ minWidth: 400 }}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        )}
        {settings && isSettingEnabled(settings, "companyVisible") && (
          <TextField
            label={t("company")}
            margin="normal"
            style={{ minWidth: 400 }}
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        )}
        {settings && isSettingEnabled(settings, "taxnumberVisible") && (
          <TextField
            label={t("taxNumber")}
            margin="normal"
            style={{ minWidth: 400 }}
            value={taxNumber}
            onChange={(e) => setTaxNumber(e.target.value)}
          />
        )}
        {settings &&
          isSettingEnabled(settings, "occupationalSafetyCardVisible") && (
            <TextField
              label={t("occupationalSafetyCard")}
              margin="normal"
              style={{ minWidth: 400 }}
              value={occupationalSafetyCard}
              onChange={(e) => setOccupationalSafetyCard(e.target.value)}
            />
          )}
        {settings && isSettingEnabled(settings, "dateOfBirthVisible") && (
          <DatePicker
            label={t("dateOfBirth")}
            value={dateOfBirth}
            sx={{ minWidth: 400, marginTop: theme.spacing(2) }}
            onAccept={(d) => setDateOfBirth(d || dayjs())}
          />
        )}
        <Button
          style={{ marginTop: theme.spacing(2) }}
          variant="contained"
          disabled={
            !email || !/.+@.+\..+/.test(email) || !firstName || !lastName
          }
          onClick={onAdd}
        >
          {t("save")}
        </Button>

        <Button
          style={{ marginTop: theme.spacing(2) }}
          variant="contained"
          disabled={
            !email || !/.+@.+\..+/.test(email) || !firstName || !lastName
          }
          onClick={() => setShowInviteModal(true)}
        >
          {t("saveAndInvite")}
        </Button>
      </Box>
        {/* Invite modal */}
        <InviteModal
          users={[email]}
          courses={courses || []}
          sendInvite={(users, courses) => {
            console.log(users, courses);
            onAddAndInvite(courses as string[]);
          }}
          isVisible={showInviteModal}
          setVisible={(val) => setShowInviteModal(val)}
        />
    </ViewContainer>
  );
};
